import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
    overrides: {
        MuiCssBaseline: {
          '@global': {
            body: {
                backgroundColor: "#1A1A1D"
            }
          },
        },
      },
    breakpoints: {
        values: {
          xs: 0,
          smallPhone: 320,
          sm: 480,
          md: 767,
          lg: 960,
          xl: 1248,
        },
    },
    palette: {
        primary: {
            main:"#2997FF"
        },
        secondary: {
            main: "#fff"
        },
        background: {
            main: "#1A1A1D",
            navbar: "#282829",
            secondary: "#4E4E50",
            lightblue: "#85C4FF",
            rose: "#D897B9"

        },
        icon: {
            primary: "#8D8D91"
        },
        text: {
            primary: "#fff"
        }
    },
    typography: {
        fontFamily: ['Rosario','Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        'sans-serif'].join(","),
        h1: {
            fontSize: "3rem",
            fontWeight: 700
        },
        h2: {
            fontSize: "2.5rem",
            fontWeight: 700,
            lineHeight: "25px"
        },
        h3: {
            fontSize: "2rem",
            fontWeight: 700,
            lineHeight: "2rem"
        },
        body1: {
            fontSize: "1.5rem"
        },
        body2: {
            fontSize: "1.3125rem"
        },
        caption: {
            fontSize: "1.125rem"
        },
        subtitle1: {
            fontSize: 12,
            fontWeight: 600,
            lineHeight: "14px"
        }
    },
})

export default theme;