/**
 *
 * @param {string} [string] - string to slice
 * @param {number} [chars] - number of chars of sliced string
 * @returns {string} - sliced string
 */

export function sliceString(string, chars) {
  if (!string) return;
  if (!chars) return string;

  if (string.length > chars) {
    return `${string.slice(0, chars)}...`;
  } else {
    return string;
  }
}
