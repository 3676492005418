/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react'
import { Box, Button, Card, makeStyles } from '@material-ui/core'
import MiniTitle from '../../common/MiniTitle'
import { ReactComponent as StarsImage } from '../../../images/stars.svg'
import { ReactComponent as CleaningProductsImage } from '../../../images/cleaning-products.svg'
import PropTypes from 'prop-types'
import theme from '../../../theme/theme'
import PlanDialog from './PlanDialog'
import ReactHtmlParser from 'react-html-parser'
import { useHistory, useLocation } from 'react-router'
import {DialogContext} from '../../../App';

const backgrounds = [
    "linear-gradient(106.1deg, #002A52 0%, #005FB8 100%)",
    "linear-gradient(106.1deg, #004A8F 0%, #007EF5 100%)",
    "linear-gradient(106.1deg, #0069CC 0%, #1F93FF 100%, #339CFF 100%)",
]

const useStyles = makeStyles({
    cardWrapper: {
        flexBasis: "100%",
        padding: 10,
        [theme.breakpoints.up("md")] : {
            flexBasis: "80%"
        },
        [theme.breakpoints.up(1024)] : {
            flexBasis: "33%"
        },
        "& a": {
            textDecoration: "none"
        }
    },
    blueCard: {
        background: "linear-gradient(106.1deg, #002A52 0%, #005FB8 100%)",
        position: "relative",
        borderRadius: 24,
        width: "100%",
        padding: 20,
        
    },
    content: {
        marginBottom: 19,
        "& *": {
            fontSize: 10,
            lineHeight: "12px",
            padding: "4px 0px",
            [theme.breakpoints.up("sm")] : {
                fontSize: 16,
                lineHeight: "20px"
            }
        },
    },
    stars: {
        position: "absolute",
        top: 5,
        left: 5
    },
    cleaningProducts: {
        position: "absolute",
        bottom: 10,
        right: 10,
    },
    cardButton: {
        borderRadius: 12,
        color: "#fff",
        fontSize: 8,
        letterSpacing: 0.8,
        lineHeight: "4px",
        padding: "5px 10px",
        [theme.breakpoints.up("sm")]: {
            fontSize: 12,
            letterSpacing: 1.2,
            lineHeight: "12px",
        }
    },
    
})


const BlueCard = ({content, title, subtitle, id, modalContent, modalBottomText, handleOpen, updateData}) => {
    const classes = useStyles()
    const handleClick = () => {
        updateData(modalContent, modalBottomText)
        handleOpen()
    }
   
    return (
        <Box className={classes.cardWrapper}>
            <Card className={classes.blueCard} style={{
                background: backgrounds[id - 1]
            }}>
                <StarsImage className={classes.stars}/>
                <MiniTitle pb={2} variant1="subtitle1" variant2="h3" smallText={subtitle} largeText={title}/>
                <Box className={classes.content}>
                    {ReactHtmlParser(content)}
                </Box>
                <Button className={classes.cardButton} variant="outlined" color="secondary" onClick={handleClick}>
                    read more
                </Button>
                <CleaningProductsImage className={classes.cleaningProducts}/>
            </Card>

        </Box>
    )
}

BlueCard.propTypes = {
    title: PropTypes.string,
    items: PropTypes.object,
    content: PropTypes.any,
    id: PropTypes.string,
    subtitle: PropTypes.string,
    modalContent: PropTypes.any,
    modalBottomText: PropTypes.string
}

export default BlueCard;