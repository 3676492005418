import { Box, Typography, makeStyles, useMediaQuery, Container } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import theme from '../../../theme/theme'
import StarryTitle from '../../common/StarryTitle'
import ReactHtmlParser, {convertNodeToElement} from 'react-html-parser'
import MinusIcon from '../../../images/minus1.svg'

const useStyles = makeStyles({
    listIcon: {
        width: 20,
        minWidth: 20,
        alignItems: "center",
        height: "1.5rem"
    },
    bottomText: {
        "& p": {
            "& a": {
                color: "#fff"
            }
        }
    },
    normalFontWeight: {
        fontWeight: 500
    },
    content: {
        "& *": {
            wordBreak: "break-word",
        },
        "& li": {
            listStyleType: "none",
            padding: "12px 16px",
            position: "relative",
            fontSize: "1.5rem",
            lineHeight: 1.5,
            "&::before": {
                content: `url(${MinusIcon})`,
                position: "absolute",
                left: -8,
                top: -6,
                padding: "12px 0px"
            }
        },
        "& ul": {
            paddingLeft: 20,
            paddingRight: 20,
            [theme.breakpoints.up("sm")] :{
                paddinglLeft: 60,
                paddingRight: 0,
            }
        },
    }
})

function transformNode(node, index) {
    const {type, name} = node;
    
    node.attribs = {}

    if(name === "style") {
        return null;
    }

    if(name === "p" && (node.children[0] && node.children[0].name === "ul")) {
        const transformedLis = node.children[0].children.map((li, index) => <li key={index + li.children[0].data}>{li.children[0].data}</li>)
        return (<ul>{transformedLis}</ul>)
    }

    if(name === "ul" && node.children[0]) {
        const transformedLis = node.children.map((li, index) => <li key={index + li.children[0].data}>{li.children[0].data}</li>)
        return (<ul>{transformedLis}</ul>)
    }

    if(type === "tag" && name === "p") {
        return convertNodeToElement(node, index, transformNode);
        
    }

    if(type === "tag" && name === "span") {
        return convertNodeToElement(node, index, transformNode);
    }

    if(type === "tag" && name === "h1") {
        return (
        <Box pb={"63px"} key="key">
            <StarryTitle key="key2" text={node.children[0].data} />
        </Box>
        )   
    }
}

const PlanDetails = ({modalContent, modalBottomText, id, helpline}) => {
    const classes = useStyles();
    const mediaSm = useMediaQuery(theme.breakpoints.up("sm"))

    return (
        <Container maxWidth="xl" component={Box} pt={mediaSm? "113px": "40px"}>
            <Box width="100%" pb={id === 3? 0 : mediaSm? "116px":"53px"}>
            <Box className={classes.content}>
                {ReactHtmlParser(modalContent, {transform: transformNode})}
            </Box>
            <Typography variant="body1" className={classes.bottomText}>
                {ReactHtmlParser(helpline)}
            </Typography>
            {modalBottomText && 
                <Box pt={4}>
                    <Typography variant="caption" className={classes.bottomText}>
                        {modalBottomText}
                    </Typography>
                </Box>
            }
            </Box>
        </Container>
    )
}

PlanDetails.propTypes = {
    modalContent: PropTypes.any,
    modalBottomText: PropTypes.string,
    id: PropTypes.number,
    helpline: PropTypes.any
}

export default PlanDetails;