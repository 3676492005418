import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import theme from "../../../theme/theme";
import StarryTitle from "../../common/StarryTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { getRestApiAddress } from "../../../utils";

const useStyles = makeStyles({
  dialogContent: {
    background: theme.palette.background.secondary,
    padding: "20px 10px",
    position: "relative",
  },
  exitButton: {
    position: "absolute",
    right: 10,
    top: 10,
    width: 50,
    height: 50,
  },
  button: {
    borderRadius: 24,
    fontSize: 16,
    lineHeight: "12px",
    letterSpacing: "0.1rem",
    padding: "10px 20px",
  },

  input: {
    background: "transparent",
    marginTop: "5px",
    "&:-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:active, :-webkit-autofill:focus":
      {
        color: "#fff !important",
        WebkitBoxShadow: "0 0 0 1000px #4E4E50 inset !important",
        WebkitTextFillColor: "#fff !important",
      },
  },
  label: {
    color: "#fff",
  },
  contact: {
    display: "none",
  },
});

const REVIEWS_URL = `${getRestApiAddress()}/reviews`;

const AddReview = ({ open, handleClose }) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [review, setReview] = useState("");
  const [reviewSent, setReviewSent] = useState(false);
  const [nameError, setNameError] = useState("");
  const [reviewError, setReviewError] = useState("");
  const [contact, setContact] = useState("");
  const [text, setText] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
    if (
      /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/.test(
        e.target.value
      )
    ) {
      setNameError("");
    }
  };

  const handlePositionChange = (e) => {
    setPosition(e.target.value);
  };

  const handleReviewChange = (e) => {
    setReview(e.target.value);
    if (e.target.value.length >= 20) {
      setReviewError("");
    }
  };

  const handleContactChange = (e) => {
    setContact(e.target.value);
  };

  const resetDialog = () => {
    setName("");
    setNameError("");
    setPosition("");
    setReview("");
    setReviewError("");
    setContact("");
    setReviewSent(false);
  };

  const handleSubmit = (e) => {
    let nameIsOk = true;
    let reviewIsOk = true;

    e.preventDefault();
    if (!/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/.test(name)) {
      setNameError("Incorrect Entry");
      nameIsOk = false;
    }
    if (!/^[\w'\-,.][^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{20,}$/.test(review)) {
      setReviewError(
        "Please type at least 20 characters, no special characters"
      );
      reviewIsOk = false;
    }
    if (nameIsOk && reviewIsOk) {
      if (!contact) {
        fetch(REVIEWS_URL, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify({
            name: name,
            position: position,
            text: review,
          }),
        })
          .then((res) => res.json())
          .then((responseText) => {
            console.log(responseText);
            if (responseText === "true") {
              setText(
                "Thank you! Your review has been submitted and is awaiting approval."
              );
            } else {
              setText("Error! Your review was not subimitted.");
            }
            setReviewSent(true);
            setName("");
            setPosition("");
            setReview("");
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  return (
    <Dialog
      maxWidth='lg'
      onEnter={resetDialog}
      onClose={handleClose}
      open={open}
      PaperProps={{ className: classes.dialogContent }}
    >
      <IconButton onClick={handleClose} className={classes.exitButton}>
        <FontAwesomeIcon icon={faTimes} />
      </IconButton>
      {reviewSent ? (
        <Box width='100%' height='100%' display='flex' justifyContent='center'>
          <Box width='80%' pt={2}>
            <Typography variant='body2' align='center'>
              {text}
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <DialogTitle>
            <StarryTitle text='Write a review'></StarryTitle>
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                autoFocus
                margin='normal'
                id='name'
                error={nameError ? true : false}
                label='Your full name'
                type='text'
                fullWidth
                helperText={nameError}
                value={name}
                onChange={handleNameChange}
                inputProps={{
                  className: classes.input,
                }}
                InputLabelProps={{
                  className: classes.label,
                }}
                required
              />
              <TextField
                margin='normal'
                id='position'
                label='Your position and/or company name'
                type='text'
                fullWidth
                value={position}
                onChange={handlePositionChange}
                inputProps={{
                  className: classes.input,
                }}
                InputLabelProps={{
                  className: classes.label,
                }}
              />
              <TextField
                multiline
                rows='5'
                margin='normal'
                id='review'
                label='What did you think of our service?'
                type='text'
                fullWidth
                value={review}
                helperText={reviewError}
                onChange={handleReviewChange}
                inputProps={{
                  className: classes.input,
                }}
                InputLabelProps={{
                  className: classes.label,
                }}
                required
                error={reviewError ? true : false}
              />
              <TextField
                margin='normal'
                id='contact'
                label='Contact'
                type='text'
                value={contact}
                onChange={handleContactChange}
                className={classes.contact}
              />
              <Box>
                <Typography variant='caption' color='secondary'>
                  * required fields
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Box display='flex' justifyContent='center' width='100%'>
                <Button
                  type='submit'
                  variant='outlined'
                  color='primary'
                  classes={{ root: classes.button }}
                >
                  Send review
                </Button>
              </Box>
            </DialogActions>
          </form>
        </>
      )}
    </Dialog>
  );
};

AddReview.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default AddReview;
