import React from 'react';
import Centerpiece from './Centerpiece';
import PageContainer from './PageContainer';
import PropTypes from 'prop-types'
import { Box, useMediaQuery } from '@material-ui/core';
import theme from '../../theme/theme';

const NewPage = ({icon, title, children}) => {
    const mediaSm = useMediaQuery(theme.breakpoints.up("sm"))
    return (
        <PageContainer>
            <Box pb={mediaSm? "64px" : "44px"}>
                <Centerpiece icon={icon} title={title}/>
            </Box>
            {children}
        </PageContainer>
    )
}

NewPage.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.any,
    pageTitle: PropTypes.string,
    metaDesc: PropTypes.string,
    pageName: PropTypes.string
}

export default NewPage;