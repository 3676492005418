import { Box, Typography, makeStyles, useMediaQuery } from '@material-ui/core'
import React from 'react'
import { ReactComponent as QuotesImg } from '../../../images/quotes.svg'
import PropTypes from 'prop-types'
import theme from '../../../theme/theme'

const useStyles = makeStyles({
    reviewCard: {
        borderRadius: 24,
        backgroundColor: theme.palette.background.secondary,
        height: 411,
        padding: 30,
        boxSizing: "border-box", 
        [theme.breakpoints.up("md")] : {
            height: 408
        }
    },
    imageBox: {
        width: 50,
        height: 50,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)"
    },
    reviewText: {
        fontSize: 12,
        fontStyle: "italic",
        fontWeight: 500,
        lineHeight: "18px",
        display: "flex",
        [theme.breakpoints.up("md")] : {
            fontSize: 16,
            lineHeight: "24px"
        }
    },
    name: {
        lineHeight: "21px",
        fontWeight: 700,
        fontSize: 18,
        [theme.breakpoints.up("md")] : {
            fontSize: 21
        }
    },
    position: {
        fontSize: 10,
        lineHeight: "12px",
        maxWidth: 220,
        [theme.breakpoints.up("md")] : {
            fontSize: 14,
            lineHeight: "14px"
        }
    }
})

const ReviewCard = ({index, name, position, review}) => {
    const isLargerThanMd = useMediaQuery(theme.breakpoints.up("md"))
    const classes = useStyles()
    const isEven = index % 2 === 0
    return (
        <Box className={classes.reviewCard}>
            <Box mb={isLargerThanMd? "30px": "20px"} display="flex" alignItems="center" flexDirection={isLargerThanMd ? "row" : "column"}>
                <Box className={classes.imageBox} bgcolor={isEven? "background.rose" : "background.lightblue"} mb={isLargerThanMd? 0: "10px"}>
                    <QuotesImg/>
                </Box>
                <Box pl={isLargerThanMd ? "10px" : 0}>
                    <Typography align={isLargerThanMd? "left" : "center" } variant="body2" className={classes.name}>{name}</Typography>
                    <Typography align={isLargerThanMd? "left" : "center" } variant="subtitle1" className={classes.position}>{position}</Typography>
                </Box>
            </Box>
            <Box pl={isLargerThanMd? 1 : 0} pr={isLargerThanMd? 1 : 0}>
                <Box className={classes.reviewText}>
                    {review}
                </Box>
            </Box>
        </Box>
    )
}

ReviewCard.propTypes = {
    index: PropTypes.number,
    name: PropTypes.string,
    position: PropTypes.string,
    review: PropTypes.string
}

export default ReviewCard;