import React from "react";
import NewPage from "../common/NewPage";
import { Box, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import Helpline from "./Helpline";
import Brochure from "./Brochure";
import StarryTitle from "./StarryTitle";
import { Helmet } from "react-helmet-async";
import { getDomainAddress } from "../../utils";
import { SocialMediaIcons } from "../SocialMediaIcons";

function transformNode(node, index) {
  const { type, name } = node;
  node.attribs = {};

  if (name === "p" && node.children[0] && node.children[0].name === "ul") {
    const transformedLis = node.children[0].children.map((li, index) => (
      <li key={index + li.children[0].data}>{li.children[0].data}</li>
    ));
    return <ul>{transformedLis}</ul>;
  }

  if (name === "ul" && node.children[0]) {
    const transformedLis = node.children.map((li, index) => (
      <li key={index + li.children[0].data}>{li.children[0].data}</li>
    ));
    return <ul>{transformedLis}</ul>;
  }

  if (type === "tag" && name === "p") {
    return convertNodeToElement(node, index, transformNode);
  }

  if (type === "tag" && name === "span") {
    return convertNodeToElement(node, index, transformNode);
  }

  if (type === "tag" && name === "h1") {
    return (
      <Box pt={4} pb={4}>
        <StarryTitle text={node.children[0].data} />
      </Box>
    );
  }
}

const useStyles = makeStyles({
  content: {
    "& *": {
      wordBreak: "break-word",
    },
    "& p, span, li": {
      fontSize: "1.5rem",
    },
    "& ul": {
      paddingLeft: 50,
    },
  },
});

const ContentPage = ({ content, icon, pageSettings, url }) => {
  const classes = useStyles();
  const schemaLdJson = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "PrimeFm",
    description: content["og_desc"],
    brand: pageSettings.brand,
    openingHours: pageSettings["business_hours"],
    image: icon,
    "@id": `${getDomainAddress()}`,
    url: `${getDomainAddress()}${url}`,
    telephone: pageSettings.phone,
    address: {
      "@type": "PostalAddress",
      streetAddress: pageSettings.streetAddress,
      addressLocality: pageSettings.addressLocality,
      postalCode: pageSettings.postalCode,
      addressCountry: pageSettings.addressCountry,
    },
  };
  return (
    <NewPage icon={icon} pageTitle={content.title}>
      <Helmet>
        <title>{content["page_title"]}</title>
        <meta name='description' content={content["meta_desc"]} />
        <meta property='og:type' content='business.business' />
        <meta property='og:title' content={content["og_title"]} />
        <meta property='og:description' content={content["og_desc"]} />
        <meta property='og:url' content={`${getDomainAddress()}${url}`} />
        <meta property='og:image' content={icon} />
        <meta
          property='business:contact_data:street_address'
          content={pageSettings.streetAddress}
        />
        <meta
          property='business:contact_data:locality'
          content={pageSettings.addressLocality}
        />
        <meta
          property='business:contact_data:region'
          content='Greater London'
        />
        <meta
          property='business:contact_data:postal_code'
          content={pageSettings.postalCode}
        />
        <meta
          property='business:contact_data:country_name'
          content={pageSettings.addressCountry}
        />
        <script type='application/ld+json'>
          {JSON.stringify(schemaLdJson)}
        </script>
      </Helmet>
      <Box className={classes.content} pt={2} pb={2}>
        {ReactHtmlParser(content.text, { transform: transformNode })}
      </Box>
      <Brochure />
      <Helpline email={pageSettings.email} phone={pageSettings.phone} />
      <SocialMediaIcons />
    </NewPage>
  );
};

ContentPage.propTypes = {
  content: PropTypes.any,
  icon: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  pageName: PropTypes.string,
  url: PropTypes.string,
  pageSettings: PropTypes.object,
};

export default ContentPage;
