import { Box, Container, CssBaseline, ThemeProvider } from "@material-ui/core";
import React, { useLayoutEffect, useState } from "react";
import { Route, Switch } from "react-router";
import "./App.css";
import Footer from "./components/common/Footer";
import Navbar from "./components/Navbar";
import theme from "./theme/theme";
import MainPage from "./components/main/MainPage";
import Plans from "./components/plans/Plans";
import ScrollToTop from "./components/common/ScrollToTop";
import ContentPage from "./components/common/ContentPage";
import VirusIcon from "./images/virus-icon.svg";
import PlanDialog from "./components/plans/components/PlanDialog";
import { HelmetProvider } from "react-helmet-async";
import { getRestApiAddress } from "./utils";

const SAMPLE_CONTENT = { title: "page title", meta_desc: "desc", text: "text" };
const SAMPLE_PLANS = {
  text: "text",
  section1: "section1",
  section2: "section2",
  section3: "section3",
  helpline: "helpline",
  modalContent: <p>sample content</p>,
  modalBottomText: "",
};

const App = () => {
  const [pageSettings, setPageSettings] = useState([]);
  const [reviewsData, setReviewsData] = useState(null);
  const [mainPageData, setMainPageData] = useState([]);
  const [contentData, setContentData] = useState([]);
  const [covidData, setCovidData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [data, setData] = useState({
    modalContent: SAMPLE_CONTENT.modalContent,
    modalBottomText: SAMPLE_CONTENT.modalBottomText,
  });
  const [readyState, setReadyState] = useState(0);

  const updateData = (modalContent, modalBottomText) => {
    setData({ modalContent, modalBottomText });
  };

  const handleOpen = () => {
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  const getReady = () => {
    setReadyState((readyState) => readyState + 1);
  };

  useLayoutEffect(() => {
    fetch(`${getRestApiAddress()}/settings`)
      .then((data) => {
        if (!data.ok) {
          throw new Error("Network response was not ok");
        }
        return data.json();
      })
      .then(({ settings }) => {
        setPageSettings(settings[0]);
        getReady();
      })
      .catch((error) => {
        console.log(error);
      });
    fetch(`${getRestApiAddress()}/reviews`)
      .then((data) => data.json())
      .then(({ reviews }) => {
        setReviewsData(reviews);
        getReady();
      })
      .catch((error) => {
        console.log(error);
      });
    fetch(`${getRestApiAddress()}/mainpage`)
      .then((data) => data.json())
      .then(({ mainPageCard }) => {
        setMainPageData(mainPageCard);
        getReady();
      })
      .catch((error) => {
        console.log(error);
      });
    fetch(`${getRestApiAddress()}/content`)
      .then((data) => data.json())
      .then(({ content }) => {
        setContentData(
          content.sort((item1, item2) => item1["title_id"] - item2["title_id"])
        );
        getReady();
      })
      .catch((error) => {
        console.log(error);
      });
    fetch(`${getRestApiAddress()}/covid`)
      .then((data) => data.json())
      .then(({ covid }) => {
        setCovidData(covid[0]);
        getReady();
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const StandardContentPage = ({ ...props }) => {
    return <ContentPage pageSettings={pageSettings} {...props} />;
  };

  const ROUTES = [
    "/maintenance",
    "/meservices",
    "/reception",
    "/env",
    "/security",
    "/helpdesk",
  ];

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {readyState === 5 ? (
          <Box height='100%' className='App' position='relative'>
            <Navbar phone={pageSettings.phone} />
            <Container maxWidth='xl'>
              <ScrollToTop />
              <Switch>
                <Route path='/' exact>
                  <MainPage
                    mainPageData={mainPageData}
                    reviewsData={reviewsData}
                    pageSettings={pageSettings}
                  />
                </Route>
                <Route path='/covid19'>
                  <Plans
                    plansData={covidData.id ? covidData : SAMPLE_PLANS}
                    icon={
                      mainPageData.length > 0
                        ? mainPageData[0].image
                        : VirusIcon
                    }
                    pageSettings={pageSettings}
                    handleOpen={handleOpen}
                    updateData={updateData}
                  />
                </Route>
                {ROUTES.map((route, index) => (
                  <Route key={route} path={route}>
                    <StandardContentPage
                      icon={
                        mainPageData[index + 2]
                          ? mainPageData[index + 2].image
                          : VirusIcon
                      }
                      content={
                        contentData[index] ? contentData[index] : SAMPLE_CONTENT
                      }
                      url={route}
                    />
                  </Route>
                ))}
              </Switch>
              {dialogOpen && (
                <PlanDialog
                  handleClose={handleClose}
                  modalContent={data.modalContent}
                  modalBottomText={data.modalBottomText}
                  email={pageSettings.email}
                  phone={pageSettings.phone}
                  helpline={
                    covidData.id ? covidData.helpline : SAMPLE_PLANS.helpline
                  }
                  id={1}
                  open={dialogOpen}
                />
              )}
            </Container>
            <Footer pageSettings={pageSettings} />
          </Box>
        ) : (
          <Box
            bgcolor='background.main'
            width='100%'
            height='100vh'
            display='flex'
            justifyContent='center'
            alignItems='center'
          ></Box>
        )}
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
