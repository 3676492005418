import { Box, Button, Drawer, IconButton, makeStyles, useMediaQuery } from '@material-ui/core'
import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from '../theme/theme';
import { ReactComponent as PrimeFmLogo } from '../images/logo.svg'

const useStyles = makeStyles({
    mobileMenuWrapper: {
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: theme.palette.background.main,
        minHeight: "100vh",
        position: "relative"
    },
    linksContainer: {
        display:"flex",
        flexDirection:"column", 
        justifyContent:"flex-start",
        alignItems:"center",
        padding: 10,
    },
    linkBox: {
        padding: "10px 15px",
        background: theme.palette.background.secondary,
        borderRadius: 24,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.5rem",
        textDecoration: "none",
        marginBottom: 5,
        marginTop: 5,
        textTransform: "none",
        border: "none",
        "&:hover": {
            background: theme.palette.background.secondary,
        },
    },
    exitButton: {
        width: 30,
        height: 30,
        position: "fixed",
        top: 5,
        right: 5,
        [theme.breakpoints.up("sm")] : {
            right: 10,
            top: 10,
            width: 50,
            height: 50,
        }
    },
    logo: {
        position: "fixed",
        zIndex: 0,
        bottom: 10,
        right: 10,
        width: 40,
        height: "auto",
    }
})

const StyledButtonLink = ({isExternal = false,...props}) => {
    const classes = useStyles();
    return (
        <Button disableRipple variant="outlined" className={classes.linkBox} component={isExternal? "a" : Link } {...props}></Button>
    )
}

const Menu = ({open, handleClose}) => {
    const isLandscape = useMediaQuery("(orientation: landscape) and (max-width: 767px)")
    const classes = useStyles()
    return (
        <Drawer transitionDuration={400} variant="temporary" onClose={handleClose} anchor="left" open={open} PaperProps={{
            className: classes.mobileMenuWrapper
        }}>
            <PrimeFmLogo className={`${classes.logo} navLogo`}/>
            <IconButton color="secondary" classes={{root: classes.exitButton}} onClick={handleClose}>
                <FontAwesomeIcon icon={faTimes} size="2x"/>
            </IconButton>
            <Box className={classes.linksContainer} style={isLandscape ? {display: "grid", gridTemplate: "1fr 1fr 1fr/1fr 1fr 1fr", gridGap: "20px"} : {justifyContent: "center"}}>
                <StyledButtonLink to="/" onClick={handleClose}>Home</StyledButtonLink>
                <StyledButtonLink to="/covid19" onClick={handleClose}>COVID-19</StyledButtonLink>
                <StyledButtonLink href="https://www.primeclean.com/" onClick={handleClose} target="_blank" rel="noreferrer" isExternal>PrimeClean</StyledButtonLink>
                <StyledButtonLink to="/maintenance" onClick={handleClose}>Maintenance Services</StyledButtonLink>
                <StyledButtonLink to="/meservices" onClick={handleClose}>M&E Services</StyledButtonLink>
                <StyledButtonLink to="/reception" onClick={handleClose}>Reception Services</StyledButtonLink>
                <StyledButtonLink to="/env" onClick={handleClose}>Environmental Services</StyledButtonLink>
                <StyledButtonLink to="/security" onClick={handleClose}>Security</StyledButtonLink>
                <StyledButtonLink to="/helpdesk" onClick={handleClose}>Help Desk</StyledButtonLink>
            </Box>
        </Drawer>
    )
}

Menu.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
}

export default Menu;