import { Box, useMediaQuery } from '@material-ui/core';
import React from 'react';
import theme from '../../theme/theme';
import PropTypes from 'prop-types'

const PageContainer = ({children, alignItems="center"}) => {
    const mediaSm = useMediaQuery(theme.breakpoints.up("sm"))
    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            alignItems={alignItems} 
            justifyContent="center" 
            pt={mediaSm? "80px":"60px"} 
            pb={mediaSm? "120px":"60px"}
        >
            {children}
        </Box>
    )
}

PageContainer.propTypes = {
    children: PropTypes.any,
    alignItems: PropTypes.string,
    pageTitle: PropTypes.string,
    metaDescription: PropTypes.string
}

export default PageContainer;