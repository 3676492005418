import React from "react";
import { Box, Container, Typography, useMediaQuery } from "@material-ui/core";
import BlueCard from "./components/BlueCard";
import theme from "../../theme/theme";
import NewPage from "../common/NewPage";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { getDomainAddress } from "../../utils";

const Plans = ({ pageSettings, plansData, icon, handleOpen, updateData }) => {
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up("md"));
  const schemaLdJson = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "PrimeFm",
    description: plansData["og_desc"],
    brand: pageSettings.brand,
    openingHours: pageSettings["business_hours"],
    image: icon,
    "@id": `${getDomainAddress()}/covid19`,
    url: `${getDomainAddress()}/covid19`,
    telephone: pageSettings.phone,
    address: {
      "@type": "PostalAddress",
      streetAddress: pageSettings.streetAddress,
      addressLocality: pageSettings.addressLocality,
      postalCode: pageSettings.postalCode,
      addressCountry: pageSettings.addressCountry,
    },
  };

  const blueCardProps = {
    email: pageSettings.email,
    phone: pageSettings.phone,
    helpline: plansData.helpline,
    handleOpen: handleOpen,
    updateData: updateData,
  };

  return (
    <NewPage
      icon={icon}
      title='COVID19'
      pageTitle='COVID19'
      metaDesc='meta description'
      noPadding
    >
      <Helmet>
        <title>{pageSettings["site_title"]}</title>
        <meta name='description' content={plansData["meta_desc"]} />
        <meta property='og:type' content='business.business' />
        <meta property='og:title' content={plansData["og_title"]} />
        <meta property='og:description' content={plansData["og_desc"]} />
        <meta property='og:url' content={`${getDomainAddress()}/covid19`} />
        <meta property='og:image' content={icon} />
        <meta
          property='business:contact_data:street_address'
          content={pageSettings.streetAddress}
        />
        <meta
          property='business:contact_data:locality'
          content={pageSettings.addressLocality}
        />
        <meta
          property='business:contact_data:region'
          content='Greater London'
        />
        <meta
          property='business:contact_data:postal_code'
          content={pageSettings.postalCode}
        />
        <meta
          property='business:contact_data:country_name'
          content={pageSettings.addressCountry}
        />
        <script type='application/ld+json'>
          {JSON.stringify(schemaLdJson)}
        </script>
      </Helmet>
      <Container
        maxWidth='xl'
        component={Box}
        pb={isLargerThanMd ? 10 : "60px"}
        pt={isLargerThanMd ? "80px" : "60px"}
      >
        <Typography variant='body1'>{plansData.text}</Typography>
      </Container>
      <Box
        display='flex'
        flexWrap='wrap'
        width='100%'
        alignItems='center'
        mb={10}
        justifyContent='center'
      >
        <BlueCard
          content={plansData.section1}
          title={plansData["s1_title"]}
          subtitle={plansData["s1_sub_title"]}
          modalContent={plansData["modal_1"]}
          modalBottomText={plansData["bottom_text_1"]}
          id='1'
          {...blueCardProps}
        />
        <BlueCard
          content={plansData.section2}
          title={plansData["s2_title"]}
          subtitle={plansData["s2_sub_title"]}
          modalContent={plansData["modal_2"]}
          modalBottomText={plansData["bottom_text_2"]}
          id='2'
          {...blueCardProps}
        />
        <BlueCard
          content={plansData.section3}
          title={plansData["s3_title"]}
          subtitle={plansData["s3_sub_title"]}
          modalContent={plansData["modal_3"]}
          modalBottomText={plansData["bottom_text_3"]}
          id='3'
          {...blueCardProps}
        />
      </Box>
      <Typography
        variant='caption'
        dangerouslySetInnerHTML={{ __html: plansData.helpline }}
      ></Typography>
    </NewPage>
  );
};

Plans.propTypes = {
  plansData: PropTypes.object,
  icon: PropTypes.string,
  pageSettings: PropTypes.object,
  handleOpen: PropTypes.func,
  updateData: PropTypes.func,
};

export default Plans;
