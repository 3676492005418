import React from "react";
import PropTypes from "prop-types";
import { ImageSlide } from "./ImageSlide";
import { getPrimeCleanPost } from "../../../utils";

const Slide = ({ imageUrl, alt, title, id }) => {
  return (
    <div>
      <a href={`${getPrimeCleanPost()}/post/${id}`}>
        <ImageSlide imageUrl={imageUrl} alt={alt} title={title} />
      </a>
    </div>
  );
};

Slide.propTypes = {
  id: PropTypes.number.isRequired,
  imageUrl: PropTypes.string.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
};

export { Slide };
