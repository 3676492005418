import { Box, makeStyles} from '@material-ui/core';
import React from 'react'
import LinkCard from './LinkCard';
import theme from '../../../theme/theme';
import PropTypes from 'prop-types'

const useStyles = makeStyles({
    cardGrid: {
        paddingBottom: 80,
        display: "grid",
        gridTemplate: "1fr /1fr",
        gridGap: "30px 20px",
        width: "initial",
        [theme.breakpoints.up("sm")] : {
            gridTemplate: "1fr 1fr /1fr 1fr",
            width: "100%"
        },
        [theme.breakpoints.up("md")] : {
            gridTemplate: "1fr 1fr /1fr 1fr 1fr",
        },
        [theme.breakpoints.up("lg")] : {
            gridTemplate: "1fr 1fr /1fr 1fr 1fr 1fr"
        },
        [theme.breakpoints.up("Xl")]: {
            gridTemplate: "1fr 1fr /auto auto auto 1fr"
        }
    }
})

const LINKS = ["/covid19", "https://www.primeclean.com", "/maintenance", "/meservices", "/reception", "/env", "/security", "helpdesk"]

const CardGrid = ({mainPageData}) => {
    const classes = useStyles();
    return (
        <Box className={classes.cardGrid}>
            {mainPageData.map(({id, title, image}, index) => 
                <LinkCard
                    image={image}
                    text={title}
                    link={LINKS[index]}
                    key={id + title}
                />
                )}
        </Box>
    )
}

CardGrid.propTypes = {
    mainPageData: PropTypes.array
}

export default CardGrid;