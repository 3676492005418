import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { sliceString } from "../../../utils";

const useStyles = makeStyles(() => ({
  postTitleBar: {
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    backgroundColor: "rgba(0,0,0,0.6)",
    textAlign: "center",
  },
  postTitle: {
    color: "#fff",
    padding: "10px",
  },
}));

const PostTitleBar = ({ title }) => {
  const { postTitleBar, postTitle } = useStyles();
  const windowWidth =
    window.innerWidth > 768 ? 34 : window.innerWidth > 280 ? 22 : 18;

  return (
    <div className={postTitleBar}>
      <h3 className={postTitle}>{sliceString(title, windowWidth)}</h3>
    </div>
  );
};

PostTitleBar.propTypes = {
  title: PropTypes.string,
};

export { PostTitleBar };
