import { Box, Typography, makeStyles, useMediaQuery } from '@material-ui/core'
import React from 'react'
import {ReactComponent as StarsImage} from '../../images/stars.svg'
import theme from '../../theme/theme';
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  
    stars: {
        position: "absolute",
        left: 0,
        bottom: "50%",
        transform: "translateY(50%)",
        zIndex: 1,
        width: 77.67,
        height: 80,
    },
    starsSmall: {
        left: "50%",
        transform: "translate(-50%, 50%)",
        width: 77.67,
        height: 80,
        position: "absolute",
        bottom: "50%"
    },
    headerText: {
        zIndex: 2,
        paddingLeft: 20,
        paddingRight: 20,
        lineHeight: 1.2, 
        [theme.breakpoints.up("sm")] : {
            marginLeft: 20,
            paddingRignt: 0,
            paddingLeft: 0
        }
    },
    bottomText: {
        "& p": {
            "& a": {
                color: "#fff"
            }
        }
    },
})

const StarryTitle = ({text}) => {
    const classes = useStyles();
    const mediaSm = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Box style={{position: "relative"}}>
            <StarsImage className={!mediaSm? classes.stars : classes.starsSmall}/>
            <Typography align={!mediaSm? "left" : "center"} className={classes.headerText} variant="h2">
                {text}
            </Typography>
        </Box>
    )
}

StarryTitle.propTypes = {
    text: PropTypes.any
}

export default StarryTitle;