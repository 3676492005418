import React, { useEffect } from 'react'
import { 
    Dialog, 
    DialogActions, 
    DialogContent, 
    makeStyles, 
    Button, 
    useMediaQuery
} from '@material-ui/core'
import PropTypes from 'prop-types'
import theme from '../../../theme/theme'
import PlanDetails from './PlanDetails'
import { useHistory, useLocation } from 'react-router';

const useStyles = makeStyles({
    dialogActions: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        "& a": {
            textDecoration: "none"
        }
    },
    dialogButton: {
        borderRadius: 24,
        width: 140,
        height: 40,
        fontSize: 16,
        lineHeight: "12px",
        letterSpacing: "0.1rem"
    },
    content: {
        paddingLeft: 0,
        paddingRight: 0,
    }
})

const PlanDialog = ({open, modalContent, modalBottomText, handleClose, helpline}) => {
    const classes = useStyles();
    const largerThanMd = useMediaQuery(theme.breakpoints.up("md"))
    const history = useHistory()
    const location = useLocation()
    
    useEffect(()=> {
        return ()=> {
            history.goForward();
            setTimeout(handleClose, 200)
        }
    }, [location.pathname])

    return (
        <Dialog 
            fullScreen={!largerThanMd} maxWidth="xl" 
            open={open} 
            PaperProps={{style: {background: theme.palette.background.main}}} 
            onClose={handleClose}
        >
            <DialogContent className={classes.content}>
                <PlanDetails 
                    modalContent={modalContent}
                    modalBottomText={modalBottomText}
                    helpline={helpline}
                />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button className={classes.dialogButton} onClick={handleClose} variant="outlined" color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )       
    }

export default PlanDialog;

PlanDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    modalContent: PropTypes.any,
    modalBottomText: PropTypes.string,
    id: PropTypes.number,
    helpline: PropTypes.any
}