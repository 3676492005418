import { Box, Typography } from '@material-ui/core';
import React from 'react'
import PropTypes from 'prop-types'


const MiniTitle = ({smallText, largeText, pb=5, variant1="body2", variant2="h2", align="left"}) => {
    return (
        <Box pb={pb} zIndex="2">
            <Typography align={align} variant={variant1}>{smallText}</Typography>
            <Typography align={align} variant={variant2}>{largeText}</Typography>
        </Box>
    )
}

MiniTitle.propTypes = {
    smallText: PropTypes.string,
    largeText: PropTypes.string,
    pb: PropTypes.number,
    variant1: PropTypes.string,
    variant2: PropTypes.string,
    align: PropTypes.string,
}

export default MiniTitle;