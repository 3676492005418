import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import MiniTitle from "../../common/MiniTitle";
import theme from "../../../theme/theme";
import { PostsSlider } from "../../postSlider";
import { getPrimeCleanPost } from "../../../utils";

const useStyles = makeStyles({
  button: {
    borderRadius: 12,
    width: 125,
    height: 29,
    fontSize: 12,
    lineHeight: "12px",
    letterSpacing: "0.1rem",
  },
  allNewsButton: {
    color: theme.palette.primary.main,
    width: "150px",
    border: "1px solid rgba(41, 151, 255, 0.5)",
    height: "40px",
    fontSize: "12px",
    borderRadius: "12px",
    letterSpacing: "0.1rem",
    backgroundColor: "rgba(41, 151, 255, 0.2)",
    textDecoration: "none",
    textTransform: "uppercase",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    allNewsLink: {
      textDecoration: "none",
    },
  },
  allNewsButtonWrapper: {
    textAlign: "center",
    padding: " 0",
    [theme.breakpoints.up("md")]: {
      padding: "20px 0",
    },
  },
  gridContainer: {
    alignItems: "center",
    "@media (max-width:681px)": {
      flexDirection: "column-reverse",
    },
  },
  partnersImage: {
    position: "absolute",
    right: "50%",
    top: 0,
    transform: "translate(50%,0)",
    zIndex: 1,
    height: 250.88,
    width: 250,
    [theme.breakpoints.up("sm")]: {
      right: 0,
      top: -20,
      transform: "translateY(0)",
      width: 300,
      height: 301.056,
    },
    [theme.breakpoints.up("xl")]: {
      height: "initial",
      width: "initial",
    },
  },
  hiddenText: {
    animation: "500ms $appear forwards ease-in",
  },
  "@keyframes appear": {
    "0%": {
      opacity: "0",
    },
    "100%": {
      opacity: "100%",
    },
  },
});

const About = ({ pageSettings }) => {
  const classes = useStyles();
  const largerThanSm = useMediaQuery(theme.breakpoints.up("sm"));
  const needToChangeDirection = useMediaQuery("(max-width:681px)");
  const [isExtended, setIsExtended] = useState(false);
  const toggleExtended = () => {
    setIsExtended(!isExtended);
  };

  const visibleText = pageSettings.text
    ? pageSettings.text.substring(0, 288)
    : "";
  const hiddenText = pageSettings.text ? pageSettings.text.substring(288) : "";

  return (
    <Box
      position='relative'
      width='100%'
      mb={largerThanSm ? "137px" : "62px"}
      display='flex'
      flexDirection='column'
      alignItems={largerThanSm ? "flex-start" : "center"}
    >
      <Grid className={classes.gridContainer} container spacing={3}>
        <Grid item sm={needToChangeDirection ? 12 : 6} xs={12}>
          <MiniTitle
            smallText={pageSettings.text_title}
            largeText={pageSettings.text_heading}
            align={largerThanSm ? "left" : "center"}
          />
          <Box pb={5} maxWidth='900px' zIndex='2'>
            <Typography
              variant='body1'
              align={largerThanSm ? "left" : "center"}
            >
              {visibleText}
              {isExtended && (
                <Typography variant='span' className={classes.hiddenText}>
                  {hiddenText}
                </Typography>
              )}
            </Typography>
          </Box>
          <Button
            variant='outlined'
            color='primary'
            classes={{ root: classes.button }}
            onClick={toggleExtended}
          >
            {isExtended ? "read less" : "read more"}
          </Button>
        </Grid>
        <Grid item sm={needToChangeDirection ? 12 : 6} xs={12}>
          <PostsSlider />
          <div className={classes.allNewsButtonWrapper}>
            <a
              href={`${getPrimeCleanPost()}/posts`}
              style={{ textDecoration: "none" }}
            >
              <Button className={classes.allNewsButton}>All news</Button>
            </a>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

About.propTypes = {
  pageSettings: PropTypes.any,
};

export default About;
